import { config } from 'config';
import { fetchWrapper } from 'helpers';
import { BehaviorSubject } from 'rxjs';
import { alertService } from 'services/alert.service';
import { campaignsService } from "./campaigns.service";
import { fetchWrapperCustomAuth } from 'helpers/fetch-wrapper-custom-auth-header';

const apiUrl = `${config.apiUrl}/api/CampaignActivity`;
const activitySubject = new BehaviorSubject(null);

export const activityService = {
    activity: activitySubject.asObservable(),
    get activityValue() { return activitySubject.value },
    get: function (id) {
        if (id) {
            let url = `${apiUrl}/${id}`
            return fetchWrapper.get(url)
                .then(activity => {
                    activitySubject.next(activity);
                    return activity;
                });
        }

        return fetchWrapper.get(apiUrl);

        //using temporary code for local testing
        //const data = campaignsService.getActivity(id)

        //return data;
    },
    getByCampaignId: function (campaignId, includeArchivedActivities) {
        let url = `${apiUrl}/GetByCampaignId?campaignId=${campaignId}&includeArchivedActivities=${includeArchivedActivities}`
        return fetchWrapper.get(url);
    },
    create: async function (data) {
        //add api post code
        await fetchWrapper.post(apiUrl, data)
            .then(response => {
                //assign new id to submitData to be used in the form for update
                data.id = response.data.id;
            });

        return data.id;

        //using temporary code for local testing
        const campaigns = campaignsService.get(data.campaignsId)[0];

        //generate new id
        const lastId = campaigns.activities.reduce((prev, current) => prev.id > current.id ? prev.id : current.id)
        data.id = lastId + 1;

        campaignsService.createActivity(data)
        console.log('activity.service-created')

        //return newly created id
        return data.id;
    },
    update: async function (data) {
        //get id from url
        //const id = history.location.pathname.split('/').pop();

        //add api post code
        const url = `${apiUrl}/${data.id}`;
        await fetchWrapper.put(url, data)
            .then(response => {
                //assign new id
                if (response.data)
                    data.id = response.data.id;

                if (response.hasErrors && response.errors.length > 0) {
                    alertService.error(JSON.stringify(response.errors));
                }


            });

        activitySubject.next(data);

        return;
        //using fake
        campaignsService.updateActivity(data)
        console.log('activity.service-updated')
    },
    delete: function (id) {
        //console.log('delete')
        //using fake
        campaignsService.deleteActivity(id)
    },
    archive: async function (id) {
        const url = `${apiUrl}/Archive/${id}`;
        return await fetchWrapper.delete(url);
    },
    unArchive: async function (id) {
        const url = `${apiUrl}/UnArchive/${id}`;
        return await fetchWrapper.put(url);
    },
    getTotalByStatus: function (activityId, abortSignal) {
        let url = `${apiUrl}/GetTotalByStatus?activityId=${activityId}`;

        return fetchWrapper.get(url, abortSignal);
    },

    getEmailHtml: function (clientIdHash, campaignActivityHistoryIdHash, contactIdHash) {
        const querystring = `ci=${clientIdHash}&ahi=${campaignActivityHistoryIdHash}&cti=${contactIdHash}`;

        return fetchWrapperCustomAuth.get(`${apiUrl}/GetEmailHtml?${querystring}`, clientIdHash);
    },
    getRateStats: function (activityId, abortSignal) {
        let url = `${apiUrl}/GetRateStats?activityId=${activityId}`;

        return fetchWrapper.get(url, abortSignal);
    },
    getFirstDateSent: function (activityId, abortSignal) {
        let url = `${apiUrl}/GetFirstDateSent?activityId=${activityId}`;

        return fetchWrapper.get(url, abortSignal);
    },
    hasSuccessfullySent: (activityId) => {
        return fetchWrapper.get(`${apiUrl}/HasSuccessfullySent?activityId=${activityId}`);
    },
    saveVersion: async function (data) {
        //add api post code
        const url = `${apiUrl}/SaveEmailSettingsVersion`;
        return await fetchWrapper.post(url, data);
    },
    getVersions: function (activityId) {
        //GetEmailContentSettingsVersions
        const url = `${apiUrl}/GetEmailContentSettingsVersions?activityId=${activityId}`;
        return fetchWrapper.get(url);
    },
    getVersion: async function (versionId) {
        //GetEmailContentSettings
        const url = `${apiUrl}/GetEmailContentSettings?versionId=${versionId}`;
        return await fetchWrapper.get(url);
    },
    GetProgressStatus: function (activityId, abortSignal) {
        let url = `${apiUrl}/GetProgressStatus?activityId=${activityId}`;

        return fetchWrapper.get(url, abortSignal);
    },
}